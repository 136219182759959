@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://cdn.syncfusion.com/ej2/20.3.56/material.css');

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

input::placeholder {
  font-size: 14px;
}

.slick-slide {
  margin-right: 0.5rem;
}

// react-horizontal-scrolling-menu library - style changes
/* chrome and chromium based */
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--inner-wrapper {
  gap: 0.5rem;
}

.react-horizontal-scrolling-menu--scroll-container {
  gap: 0.5rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

// global scrollbar style changes
::-webkit-scrollbar {
  background-color: #fff;
  width: 13px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}

::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 3px solid #fff;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 3px solid #f4f4f4;
}

::-webkit-scrollbar-button {
  display: none;
}

.e-pdfviewer:not(.e-pv-mobile-view) .e-pv-main-container {
  min-height: 1070px;
}

.introduction-screen-iMac {
  position: relative;
  height: 120%;
  margin-top: 4%;
  z-index: 2;
}

.introduction-screen-background-swirls {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}
